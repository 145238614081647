import React from "react";
import { Script, useStaticQuery, graphql } from "gatsby";
import { element } from "prop-types";

export const wrapPageElement = ({ element }) => {

    return (
        <>
            {element}
            <Script src="/schema.js" />
        </>
    )
}